@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorGrey50);
  }

  &:target {
    scroll-margin-top: var(--topbarHeight);
    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.sectionContent {
    padding: 32px 0;
    position: relative;
    word-break: break-word;
    background-color: rgba(255, 255, 255, 0.4); /* Semi-transparent white background */
    color: black; /* Black text */
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    @media (--viewportMedium) {
        padding: 64px 0;
        font-size: 20px; /* Increase the font size on larger screens */
    }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
