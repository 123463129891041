@import '../../../../styles/customMediaQueries.css';

/* Common styles */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.33;
  font-weight: bold;
  color: var(--colorGrey900);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

/* Specific styles */
.h1 {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-weight: 800;
  /* Note: text-wrap is experimental CSS feature. Most browsers don't support it */
  text-wrap: balance;

  @media (--viewportMedium) {
    font-size: 48px;
  }

  /* If �& + *� if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 24px;
  }
}

.h2 {
  font-size: 32px;

  /* If �& + *� if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 16px;
  }
}

.h3 {
  font-size: 24px;
}

.h4 {
  font-size: 21px;
}

.h3,
.h4,
.h5,
.h6 {
  /* If �& + *� if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 8px;
  }
}
